<template>
  <div class="pt-12 pb-5 px-8">
    <v-row>
      <v-col cols="4">
        <div class="text-sub-title--prm-light--w-bold">{{ $t('common.contract') }}</div>
      </v-col>
      <v-col>
        <div class="text-title--medium-gray--w-bold">
          {{ contract.productType.name }}
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="4">
        <div class="text-sub-title--prm-light--w-bold">{{ $t('common.contractNumber') }}</div>
      </v-col>
      <v-col>
        <div class="text-title--medium-gray--w-regular">
          {{ contract.client.memberId }}
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="5">
        <div class="text-sub-title--prm-light--w-bold">{{ $t('common.contractAutomaticRenewalSetting') }}</div>
      </v-col>
      <v-col>
        <div
          v-if="contract.autoRenewal"
          class="text-title--medium-gray--w-regular"
        >
          {{ $t('common.autoRenewContract') }}
        </div>
        <div v-else class="text-title--medium-gray--w-regular">
          {{ $t('common.notAutoRenewContract') }}
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="5">
        <div class="text-sub-title--prm-light--w-bold">{{ $t('common.contractDuration') }}</div>
      </v-col>
      <v-col>
        <div class="text-title--medium-gray--w-regular">{{ contractDuration }}</div>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="5">
        <div class="text-sub-title--prm-light--w-bold">{{ $t('common.contractStartDate') }}</div>
      </v-col>
      <v-col>
        <div class="text-title--medium-gray--w-regular">{{ contract.startDate.replaceAll('-', '/') }}</div>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="5">
        <div class="text-sub-title--prm-light--w-bold">
          {{ $t('common.contractExpirationDate') }}
        </div>
      </v-col>
      <v-col>
        <div class="text-title--medium-gray--w-regular">{{ contract.endDate.replaceAll('-', '/') }}</div>
      </v-col>
    </v-row>
    <v-divider class="mt-4"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'ContractDetail',
  props: {
    contract: Object
  },
  computed: {
    contractDuration () {
      const today = new Date()
      const dif = today - new Date(this.contract.startDate)
      const nMonth = Math.floor(dif / 1000 / 60 / 60 / 24 / 30)
      let year = Math.floor(nMonth / 12)
      let month = Math.floor(nMonth % 12)
      if (year < 0) {
        // this could happen
        year = 0
        month = 0
      }
      return `${year}${this.$t('common.year')}${month}${this.$t('common.month')}`
    }
  }
}
</script>
