<template>
  <div class="bg-very-light-shade-gray pb-16 mb-n6">
    <Breadcrumbs>
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container px-5">
      <ContractDetails v-if="contract" :contract="contract" />
      <div class="px-5">
        <v-btn
          elevation="0"
          v-if="contract !== ''"
          class="text-title --w-bold bg-primary-light --white"
          rounded
          block
          x-large
          @click="setContract"
          :disabled="!$isMainUser()"
        >
          {{ $t("common.contractAutomaticRenewalSetting") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import ContractDetails from '@/components/ContractDetails'

export default {
  name: 'ContractInformation',
  components: {
    Breadcrumbs,
    ContractDetails
  },
  methods: {
    setContract () {
      this.$router.push('/home/contract-information/confirmation')
    }
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        this.contract = await this.$store.dispatch('myVsContract', {
          apollo: this.$apollo
        })
      })
    })
  },
  data () {
    return {
      contract: null,
      isCancelDialog: false
    }
  }
}
</script>
